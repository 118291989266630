<template>
  <el-row >
    <el-col class="top" :sapn="24" >
      <!-- :width="width" -->
       <!-- arrow="always" -->
      <el-carousel
        :interval="5000"
        arrow="hover"
        :height="imgHeight"
        indicator-position="inside"
        ref="carousel"
        class="custom-carousel"
      >
        <el-carousel-item   v-for="(item, index) in (centerNavList.bannerList || []).slice(0,10)" :key="index"   @click.native="linkTo">
          <img ref="bannerHeight" :src="centerNavList.bannerList[index].img"  @load="imgLoad" :alt="item.title"/>
        </el-carousel-item>
      </el-carousel>
    </el-col>
      <!-- <el-col :sapn="24" class="bottom">
        <div v-for="(item, index) in centerNavList.activityDetail" :key="index" >
          <img v-if='item'  :src="item.pic" alt="图片"  @click="jumpUrl(item.url,item.name)"/>
        </div>
      </el-col> -->
  </el-row>
</template>
<style>
/* @media screen and (max-width: 1280px) {
  .bottom > div {
    width: 175px !important;
  }
} */
/* .bottom {
  height: 205px;
  margin-top:4px;
  box-sizing: border-box;
}
.bottom > div {
  height: 100%;
  margin-right:9px;
  width: 245px;
  float: left;
  cursor: pointer;
   overflow: hidden;
}
.bottom > div:hover{
    box-shadow: 0px 5px 15px -8px #949090;
}
.bottom > div > img {
  width: 100%;
  height: 100%;
}
.bottom > div:last-child {
  margin-right: 0px;
} */
.top {
  width: 100%;
  /* height:400px; */
  overflow: hidden;
}
.custom-carousel .el-carousel__arrow {
  width: 40px; /* 根据需要调整宽度 */
  height: 60px; /* 根据需要调整高度 */
  background-color: rgba(31, 45, 61, 0.11); /* 背景颜色 */
  border: none; /* 去除边框 */
  font-size: 24px; /* 字体大小 */
  color: #fff; /* 字体颜色 */
  border-radius: 4px; /* 圆角 */
  top: 50%; /* 垂直居中 */
  transform: translateY(-50%); /* 垂直居中 */
  margin-top: 0; /* 重置默认的margin */
}
.custom-carousel .el-carousel__arrow--left {
  left: 100px; /* 左箭头向左移动，具体值根据需要调整 */
}
 
.custom-carousel .el-carousel__arrow--right {
  right:100px; /* 右箭头向右移动，具体值根据需要调整 */
}
.el-carousel--horizontal{
  height: 100%;
}
/* .el-carousel__container{
  height: 100%;
} */
.el-carousel__item{
  cursor: pointer;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  /* line-height:400px; */
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>

<style lang="less" scoped>
@import "~style/index.less";

.el-carousel__item {
  img {
      width: 100%;
    }
}
// .zone {
//   background: black;
//   width: 755px;
//   el-main {
//     width: 100%;
//     height: 186px;
//     background: blue;
//   }
// }
</style>

<script>
export default {
  name: "CenterNav",
    inject:['reload'],
  data() {
    return {
      // width: "100%",
      picsArr:[],
      banner:[],
      imgHeight: '',
    };
  },
  props: {
    // centerNavLists: Array 
    centerNavList: {
      type: Object,
    },
  },
   //监听执行
  //  watch: {
  //    "centerNavList": function (newValue) {
  //       if(newValue) {
  //         console.log('组件里传入的轮播图数据',this.centerNavLists)
  //       }
  //   },
  // },
  created() {
    console.log('组件111里传入的轮播图数据',this.centerNavList.bannerList)
  },
  methods: {
    linkTo(){
// item.advTarget  外联1  详情页  2  活动页  3-->
      // v-if="item.advTarget==2" @click="jumpDetail(item.goodsId,item.isRecentExpiration)"
      let activeIndex = this.$refs.carousel.activeIndex
      if(this.centerNavList.bannerList[activeIndex].advTarget==2){
          // 跳商品详情
        this.jumpDetail(this.centerNavList.bannerList[activeIndex].goodsId,this.centerNavList.bannerList[activeIndex].isRecentExpiration)
      }else if(this.centerNavList.bannerList[activeIndex].advTarget==1){
         if(this.centerNavList.bannerList[activeIndex].url==''){
            return false
         }else{
          // 跳外链接
                  let str=this.centerNavList.bannerList[activeIndex].url
                
                  if(str.includes('amp;')){
                    str=str.replace(/amp;/,"")
                  }
                  // window.location.href=str
                  if(str.includes('topicPage')){
                    let tagId=str.split('Id=')[1]
                    this.$router.push({name:"TopicPage",query:{Id:tagId}})
                  }else{
                    window.open(str,'_self');
                  }
         }
        
      }else{
        if(this.centerNavList.bannerList[activeIndex].url==''){
            return false
        }else{
             // 跳活动链接
            window.location.href=this.centerNavList.bannerList[activeIndex].url
        }
      
        // this.jumpUrl(this.bannerList[activeIndex].url,this.bannerList[activeIndex].title)
      }
      // this.$router.push(this.bannerList[activeIndex].url)
      
    },

    jumpUrl(urlType,name) {
      const query = urlType.split("?")[1].split("=")[1]
      let tagId = query;
       this.$router.push({name:"MarketingList",query:{tagId:tagId,name:name}})
    },
     jumpDetail(id,isRecentExpiration) {
      this.$router.push({ name: "ProductDetail", query: { id: id,isRecentExpiration:isRecentExpiration } });
    },
    imgLoad() {
			// 获取图片高度
      if (this.$refs.bannerHeight) {
        this.$nextTick(() => {
          this.imgHeight = this.$refs.bannerHeight['0'].height + 'px'
        })
      }
		},
  },
 
  
  components: {},
  mounted() {
		this.imgLoad()
		window.onresize = () => {

      this.imgLoad()
		}
	},
  destroyed() {
		window.onresize = null
	},
};
</script>
